import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styles from "./articulos-de-interes.module.scss"

export default props => {
  const { data } = useStaticQuery(graphql`
    query {
      data: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "//articulos-de-interes//" } }
        sort: { fields: fileAbsolutePath }
      ) {
        edges {
          node {
            excerpt
            html
            frontmatter {
              title
            }
            parent {
              ... on File {
                name
              }
            }
          }
        }
      }
    }
  `)

  const articulos = data.edges.map(({ node }) => ({
    slug: node.parent.name,
    html: node.html,
    excerpt: node.excerpt,
    ...node.frontmatter,
  }))

  const distanceToTop = el => Math.floor(el.getBoundingClientRect().top)
  if (typeof window !== "undefined") {
    window.addEventListener(
      "scroll",
      evt => {
        var currentHash = ""
        document.querySelectorAll(".hash-section").forEach(each => {
          var distance = distanceToTop(each)
          var hash = each.id
          if (distance < 150 && distance > 0 && currentHash != hash) {
            window.location.hash = hash
            currentHash = hash
          }
        })
      },
      false
    )
    window.onhashchange = function() {
      let hash = window.location.hash

      document
        .querySelectorAll(".hash-link")
        .forEach(link => link.classList.remove(styles.active))
      document
        .querySelector('a[href="'+hash + '"]')
        .classList.add(styles.active)
    }
  }
  return (
    <section
      id="articulos-de-interes"
      className="pt-5"
      style={{
        backgroundColor: "white",
      }}
    >
      <div className="container-xl">
        <div className="row justify-content-between">
          <div className="col-lg-4">
            <ul
              id="content-list"
              className="nav flex-column justify-content-start py-3 ml-0"
              style={{
                position: "sticky",
                top: 50,
              }}
            >
              {articulos.map((articulo, index) => (
                <li className="nav-item mb-0">
                  <a
                    className={"hash-link nav-link text-primary py-1 " + styles.navLink}
                    href={"#" + articulo.slug}
                  >
                    {index + 1 + ". " + articulo.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="col-lg-7">
            {articulos.map((articulo, index) => (
              <div id={articulo.slug} className="hash-section py-5">
                <a
                  className="d-block d-sm-none text-decoration-none"
                  href="#content-list"
                  style={{
                    position: "sticky",
                    top: 0,
                  }}
                >
                  <h2
                    style={{
                      color: "black",
                      backgroundColor: "white",
                      borderBottom: "1px solid #ececec",
                      paddingTop: "1rem",
                      paddingBottom: "1rem",
                    }}
                  >
                    {" "}
                    {index + 1 + ". " + articulo.title}
                  </h2>
                </a>
                <h2
                  className="d-none d-sm-block"
                  style={{
                    color: "black",
                    backgroundColor: "white",
                    borderBottom: "1px solid #ececec",
                    paddingTop: "1rem",
                    paddingBottom: "1rem",
                    position: "sticky",
                    top: 0,
                  }}
                >
                  {" "}
                  {index + 1 + ". " + articulo.title}
                </h2>

                <div dangerouslySetInnerHTML={{ __html: articulo.html }} />
                <div className="d-flex justify-content-end">
                  <Link
                    to="/contacto/"
                    className="btn btn-primary mt-4 w-100 d-block d-sm-none"
                  >
                    Contacto
                    <i className="far fa-comment ml-3"></i>
                  </Link>
                  <Link
                    to="/contacto/"
                    className="btn btn-primary mt-4 d-none d-sm-block"
                  >
                    Contacto
                    <i className="far fa-comment ml-3"></i>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
