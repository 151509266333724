import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PageHeader from "../components/page-header"
import NavbarTop from "../components/navbar-top"

import ArticulosDeInteres from "../sections/articulos-de-interes"

const IndexPage = ({data}) => (
  <Layout>
    <SEO title="Artículos de Interés" />
    <NavbarTop />
    <PageHeader title="Artículos" bg={data.file.childImageSharp.fluid}/>
    <ArticulosDeInteres />
  </Layout>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "articulos-de-interes.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default IndexPage
